import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import {
	Button,
	Container,
	Grid,
	useMediaQuery,
	useTheme
} from '@mui/material';
import Stack from '@mui/system/Stack';
import { useNavigate } from 'react-router-dom';
import Pathnames from '../../routes/pathnames';
import Box from '../box/Box';
import Divider from '../divider/Divider';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';

const FaqHome = () => {
	const navigate = useNavigate();
	const t = useTranslate('faq');
	const tt = useTranslate('buttons');
	const ttt = useTranslate('about_us');
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			<Divider />
			<Container>
				<Grid
					container
					spacing={2}
					justifyContent="space-between"
					textAlign={isMobile ? 'center' : 'left'}
				>
					<Grid item xs={5}>
						<Stack direction="row" alignItems="center">
							{!isMobile && (
								<LiveHelpOutlinedIcon
									sx={{ width: 64, height: 64 }}
									color="info"
								/>
							)}
							<Box mx={isMobile ? 0 : 6} my={6}>
								<Typography variant="h3" mb={2}>
									{t('title')}
								</Typography>
								<Typography mb={2}>{t('desccription')}</Typography>
								<Button
									fullWidth={false}
									variant="outlined"
									onClick={() => navigate(Pathnames.FAQ)}
								>
									{tt(`knowMore`)}
								</Button>
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={1} textAlign="center">
						<Divider orientation="vertical" sx={{ width: '10px' }} />
					</Grid>
					<Grid item xs={5}>
						<Stack direction="row" alignItems="center">
							{!isMobile && (
								<InfoOutlinedIcon sx={{ width: 64, height: 64 }} color="info" />
							)}
							<Box mx={isMobile ? 0 : 6} my={6}>
								<Typography variant="h3" mb={2}>
									{ttt('title')}
								</Typography>
								<Typography mb={2}>{ttt('description')}</Typography>
								<Button
									variant="outlined"
									onClick={() => navigate(Pathnames.ABOUT_US)}
								>
									{tt(`knowMore`)}
								</Button>
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default FaqHome;

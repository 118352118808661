import { Helmet } from 'react-helmet-async';
import useApp from '../app/useApp';

interface Props {
	title: string;
	description: string;
	name?: string;
	type?: string;
}

const Seo = ({ title, description, name, type }: Props) => {
	const { app } = useApp();

	return (
		<Helmet>
			{/* Standard metadata tags */}
			<title>
				{title} | {app.company}
			</title>
			<meta name="description" content={description} />

			{/* Facebook tags */}
			<meta property="og:type" content={type} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			{/* Twitter tags */}
			<meta name="twitter:creator" content={name} />
			<meta name="twitter:card" content={type} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
		</Helmet>
	);
};

export default Seo;

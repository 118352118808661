import Stack from '@mui/material/Stack';
import { useLocation } from 'react-router-dom';
import Pathnames from '../../routes/pathnames';
import Link from '../link/Link';
import useTranslate from '../translation/useTranslate';

export const NAVIGATION_LIST: TypeNavigation[] = [
	{ id: 1, name: 'offer_job', to: Pathnames.JOB_OFFERS },
	{ id: 2, name: 'faq', to: Pathnames.FAQ },
	/* { id: 1, name: 'reference', to: Pathnames.REFERENCES }, */
	{ id: 4, name: 'about_us', to: Pathnames.ABOUT_US },
	{ id: 5, name: 'contact', to: Pathnames.CONTACT }
];
export interface TypeNavigation {
	id: number;
	name: string;
	to: string;
}

const Navigation = () => {
	const location = useLocation();
	const t = useTranslate('navigation');
	const isSelected = NAVIGATION_LIST.find(
		(nav) => nav.to === location.pathname
	);

	return (
		<Stack my={2} direction="row" gap={3}>
			{NAVIGATION_LIST.map((item: TypeNavigation) => (
				<Link
					key={item.name}
					to={item.to}
					selected={isSelected?.to === item.to ?? false}
				>
					{t(item.name)}
				</Link>
			))}
		</Stack>
	);
};

export default Navigation;

import React from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import AboutUs from '../components/aboutUs/AboutUs';
import Login from '../components/admin/Login';
import Contact from '../components/contact/Contact';
import Faq from '../components/faq/Faq';
import Job from '../components/job_offers/Job';
import JobOffers from '../components/job_offers/JobOffers';

import Layout from '../layout';
import AdminLayout from '../layout/AdminLayout';
import Pathnames from '../routes/pathnames';

const Home = React.lazy(() => import('../components/public/Home'));
const Maintenance = React.lazy(() => import('./Maintenance'));

const Routes = () => (
	<>
		{process.env.REACT_APP_MAINTENANCE === 'true' && (
			<RoutesDom>
				<Route
					path="/"
					element={
						<React.Suspense fallback={<>...</>}>
							<Maintenance />
						</React.Suspense>
					}
				/>
			</RoutesDom>
		)}

		{process.env.REACT_APP_MAINTENANCE === 'false' && (
			<RoutesDom>
				<Route
					path={Pathnames.HOME}
					element={
						<React.Suspense fallback={<>...</>}>
							<Layout />
						</React.Suspense>
					}
				>
					<Route index element={<Home />} />
					<Route path={Pathnames.JOB_OFFERS} element={<JobOffers />} />
					<Route
						path={`${Pathnames.JOB_OFFERS}/:id/:pathname`}
						element={<Job />}
					/>
					<Route path={Pathnames.ABOUT_US} element={<AboutUs />} />
					<Route path={Pathnames.FAQ} element={<Faq />} />
					<Route path={Pathnames.CONTACT} element={<Contact />} />
					<Route path="*" element={<>Nomatch</>} />
				</Route>
				<Route
					path={Pathnames.ADMIN}
					element={
						<React.Suspense fallback={<>...</>}>
							<AdminLayout />
						</React.Suspense>
					}
				>
					<Route index element={<Login />} />
				</Route>
			</RoutesDom>
		)}
	</>
);

export default Routes;

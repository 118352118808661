import { configureStore } from '@reduxjs/toolkit';
import { appSlice } from '../components/app/useApp';
import { montagesSlice } from '../components/job_offers/useJobOffers';

export default configureStore({
	reducer: {
		app: appSlice.reducer,
		montages: montagesSlice.reducer
	}
});

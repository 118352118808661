import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const StyledAccordion = styled((props: AccordionProps) => (
	<Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.divider}`,

	'&:before': {
		display: 'none'
	},

	'& .MuiAccordionSummary-content.Mui-expanded .MuiTypography-body1': {
		fontWeight: 600
	},

	'& .MuiAccordionSummary-content': {
		// textDecoration: "underline"
	}
}));

export type AccordionItem = {
	id: string;
	title: string;
	description?: string;
	content: string;
};

interface Props {
	list: AccordionItem[];
}

const AccordionList = ({ list }: Props) => {
	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	return (
		<>
			{list.map((item: AccordionItem) => (
				<StyledAccordion
					elevation={0}
					expanded={expanded === item.id}
					onChange={handleChange(item.id)}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ width: '66%', flexShrink: 0 }}>
							{item.title}
						</Typography>
						{item.description && (
							<Typography sx={{ color: 'text.secondary' }}>
								{item.description}
							</Typography>
						)}
					</AccordionSummary>
					<AccordionDetails>
						<Typography>{item.content}</Typography>
					</AccordionDetails>
				</StyledAccordion>
			))}
		</>
	);
};

export default AccordionList;

import { Container } from '@mui/material';
import AccordionList, { AccordionItem } from '../accordionList/AccordionList';
import HeaderPage from '../page/HeaderPage';
import useTranslate from '../translation/useTranslate';

export const FAQ_LIST: AccordionItem[] = [
	{
		id: '01',
		title: 'Je potrebné mať živnostenské oprávnenie?',
		content:
			'Projekty sú dostupné len živnostníkom s platným živnostenským oprávnením, vzdelaním a preukázateľnou praxou v odbore. Na zákazke pracujete ako samostatný subdodávateľ.'
	},
	{
		id: '02',
		title: 'Ako dobre musím vedieť po nemecky?',
		content:
			'Počas vykonávania projektu budete v kontakte s nemecky hovoriacim zástupcom klienta. Preto je potrebné sa vedieť dorozumieť na stavbe po nemecky.'
	},
	{
		id: '03',
		title: 'Čo je formulár A1? Kde si ho vybavím?',
		content: `Formulár A1 je potvrdenie sociálnej poisťovne, ktoré preukazuje skutočnosť, že aj počas montáže v zahraničí ste naďalej poistencom Slovenskej republiky, a že si odvody platíte na Slovensku.
                
            Formulár vám na základe zmluvy o dielo/objednávky vystaví príslušná pobočka sociálnej poisťovne (príslušnosť sa riadi sídlom vašej živnosti).
            Ak zvyčajne vykonávate činnosť v dvoch alebo viacerých členských štátoch EÚ je možné požiadať si o formulár A1 prostredníctvom žiadosti o určenie uplatniteľnej legislatívy.
            
            Sociálna poisťovňa vám väčšinou potvrdí vašu žiadosť a formulár vystaví v priebehu 60 dní. Na základe potvrdenej žiadosti môžete vycestovať.`
	},
	{
		id: '04',
		title: 'Je potrebný výpis z registra trestov?',
		content:
			'Výpis z registra trestov je pri niektorých zákazkách podmienkou. Výpis si môžete zaobstarať na ktorejkoľvek pobočke Slovenskej pošty. '
	},
	{
		id: '05',
		title: 'Aký je zárobok na zákazke?',
		content:
			'Výška celkového zárobku na konkrétnom projekte je podmienená rozsahom zákazky a jej trvaním.'
	},
	{
		id: '06',
		title: 'Ako budem fakturovať?',
		content:
			'Počas zákazky si vediete časový denník, ktorý v pravidelných intervaloch potvrdzuje klient. Po odpracovaní posledného dňa v mesiaci vystavíte na základe potvrdeného denníka faktúru.'
	},
	{
		id: '07',
		title: 'Kedy dostanem peniaze?',
		content: 'Faktúra je uhradená každý mesiac na bankový účet.'
	},
	{
		id: '08',
		title: 'Ubytovnie',
		content:
			'Ubytovanie hľadáme čo najbližšie k projektu v primeranom štandarde.'
	}
];

const Faq = () => {
	const t = useTranslate('faq');

	return (
		<Container sx={{ mb: 4 }} maxWidth="md">
			<HeaderPage title={t('title')} />
			<AccordionList list={FAQ_LIST} />
		</Container>
	);
};

export default Faq;

import ReactGA from 'react-ga4';

const ga4 = {
	init(TRACKING_ID: string) {
		ReactGA.initialize(TRACKING_ID);
	},

	sendEvent(hitType: string, page: string) {
		ReactGA.send({ hitType, page });
	},

	event(category: string, action: string, label: string) {
		ReactGA.event({ category, action, label });
	}
};

export default ga4;

import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { StateProps } from '../../@types/states';
import useAxiosInstance from '../../hooks/useAxiosInstance';

export interface JobOfferType {
	title: string;
	description: string;
	location: string;
	salary: string;
	startDate: string;
	top: boolean;
	pathname: string;
	id: 'string';
}
export interface MontagesSlice {
	list: JobOfferType[];
	offer: JobOfferType | null;
}

const initialState: MontagesSlice = {
	list: [],
	offer: null
};

export const montagesSlice = createSlice({
	name: 'offers',
	initialState,
	reducers: {
		offer: (state, action) => {
			state.offer = action.payload;
		},
		list: (state, action) => {
			state.list = action.payload;
		},
		reset: (state) => {
			state.list = [];
			state.offer = null;
		}
	}
});

const useJobOffers = () => {
	const axios = useAxiosInstance();
	const dispatch = useDispatch();
	const list = useSelector((state: StateProps) => state.montages.list);
	const offer = useSelector((state: StateProps) => state.montages.offer);

	const getList = () => {
		dispatch({ type: 'offers/list', payload: [] });

		axios
			.get('data/montages.json')
			.then((response) =>
				dispatch({ type: 'offers/list', payload: response.data })
			)
			.catch(() => {
				//
			});
	};

	const getOffer = (id: JobOfferType['id']) => {
		const response = list.find((item) => item.id === id);
		dispatch({ type: 'offers/offer', payload: response });
		/* axios
			.get('http://localhost:3000/data/offer.json')
			.then((response) =>
				dispatch({ type: 'offers/offer', payload: response.data })
			)
			.catch(() => {
				//
			}); */
	};

	const reset = () => {
		dispatch({ type: 'offers/reset' });
	};

	return {
		list,
		offer,
		getList,
		getOffer,
		reset
	};
};

export default useJobOffers;

import { styled } from '@mui/material';
import { Link as Lnk } from 'react-router-dom';
import palette from '../../theme/palette';

const StyledComponent = styled('div')({
	'& a': {
		fontFamily: 'Open Sans',
		color: palette?.text?.primary,
		fontSize: '1.0rem',
		fontWeight: 500,
		textDecoration: 'none'
	}
});

interface Props {
	to: string;
	children: string;
	selected: boolean;
}

const Link = ({ to, children, selected = false }: Props) => (
	<StyledComponent>
		<Lnk to={to} style={{ fontWeight: (selected && 600) || 400 }}>
			{children}
		</Lnk>
	</StyledComponent>
);

export default Link;

import axios from 'axios';

const useAxiosInstance = () => {
	// const app = useSelector((state: StateProps) => state.app);
	// const locale = useSelector((state: StateProps) => state.locale);
	const axiosInstance = axios.create({
		baseURL: '',
		headers: {
			'Accept-Language': 'en',
			Accept: 'application/json',
			'Content-Type': 'application/json'
			//  Authorization: 'Bearer ' + localStorage.getItem('access_token'),
		}
	});

	return axiosInstance;
};

export default useAxiosInstance;

import { ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import palette from '../palette';

const MuiAppBar: {
	defaultProps: ComponentsProps['MuiButton'];
	variants: ComponentsVariants['MuiButton'];
} = {
	defaultProps: {
		style: {
			//	background: 'transparent',
			//	boxShadow: 'none',
			// color: palette?.text?.primary
		}
	},
	variants: [
		{
			props: {
				variant: 'text',
				color: 'primary'
			},
			style: {
				color: palette.text?.primary
			}
		},
		{
			props: { variant: 'contained', color: 'primary' },
			style: {
				// background: palette.primary?.main['500']
			}
		}
	]
};

export default MuiAppBar;

import { List, ListItemButton, ListItemText, styled } from '@mui/material';
import theme from '../../theme/theme';
import { AccordionItem } from '../accordionList/AccordionList';
import { FAQ_LIST } from '../faq/Faq';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';

const StyledComponent = styled('div')({
	'& .MuiList-dense': {
		'& .MuiTypography-body2': {
			margin: 0
		},

		'& .MuiListItemText-root': {
			color: 'white'
		}
	},

	'& .MuiSvgIcon-root': {
		color: theme.palette.grey['500']
	},

	'& .MuiTypography-body1': {
		textTransform: 'uppercase',
		letterSpacing: 2
	},
	'& .MuiTypography-body2 a': {
		color: 'white',
		fontWeight: 600,
		textDecoration: 'none'
	},
	'& .MuiListItemButton-root:hover': {
		backgroundColor: 'transparent',

		'& span': {
			marginBottom: 0,
			textDecoration: 'underline'
		}
	}
});

const FooterFaq = () => {
	const t = useTranslate('faq');
	const selectedIds: string[] = ['01', '02', '03'];
	const list = FAQ_LIST.filter((item) => selectedIds.includes(item.id));

	return (
		<StyledComponent>
			<Typography variant="h5">{t('title')}</Typography>
			<List disablePadding dense>
				{list.map((item: AccordionItem) => (
					<ListItemButton
						disableGutters
						onClick={() => {
							//
						}}
					>
						<ListItemText primary={item.title} />
					</ListItemButton>
				))}
			</List>
		</StyledComponent>
	);
};

export default FooterFaq;

import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import TodayIcon from '@mui/icons-material/Today';
import { Grid, styled } from '@mui/material';
import theme from '../../theme/theme';
import Box from '../box/Box';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';

const LIST_TEXT = [
	{
		id: 0,
		name: 'item1.title',
		desc: 'item1.desc',
		ico: <TodayIcon />
	},
	{
		id: 1,
		name: 'item2.title',
		desc: 'item2.desc',
		ico: <DateRangeIcon />
	},
	{
		id: 2,
		name: 'item3.title',
		desc: 'item3.desc',
		ico: <EuroSymbolIcon />
	},
	{
		id: 3,
		name: 'item4.title',
		desc: 'item4.desc',
		ico: <BedroomParentIcon />
	}
];

const StyledComponent = styled('div')({
	'& .MuiTypography-body1': {
		// fontSize: 16,
		fontWeight: 600,
		marginBottom: 10
	},
	'& .MuiTypography-body2': {},
	'& .MuiListItemIcon-root': {
		minWidth: 32
	},
	'& .MuiCardMedia-img': {
		borderRadius: theme.shape.borderRadius
	},
	'& .MuiSvgIcon-root': {
		width: 52,
		height: 52,
		color: theme.palette.info.main
	}
});

const Benefits = () => {
	const t = useTranslate('advantageOfCooperation');

	return (
		<StyledComponent>
			<Typography variant="h3" mb={4}>
				{t('title')}
			</Typography>
			<Grid container spacing={4} alignItems="center" alignContent="center">
				{LIST_TEXT.map((item) => (
					<Grid key={item.id} item xs={12} sm={6} md={4} minHeight={200}>
						<Box textAlign="center">{item.ico}</Box>

						<Typography variant="body1" textAlign="center">
							{t(item.name)}
						</Typography>
						<Typography variant="body2" textAlign="center">
							{t(item.desc)}
						</Typography>
					</Grid>
				))}
			</Grid>
		</StyledComponent>
	);
};

export default Benefits;

import MenuIcon from '@mui/icons-material/Menu';
import {
	Box,
	IconButton,
	List,
	ListItemButton,
	ListItemText
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../logo/Logo';
import LangSwitcher from '../translation/LangSwitcher';
import useTranslate from '../translation/useTranslate';
import { NAVIGATION_LIST, TypeNavigation } from './Navigation';

type Anchor = 'left' | 'right';

const Position: Anchor = 'left';

const MobileNavigation = () => {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();
	const t = useTranslate('navigation');
	const isSelected = NAVIGATION_LIST.find(
		(nav) => nav.to === location.pathname
	);

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setIsOpen(open);
		};

	return (
		<>
			<IconButton onClick={toggleDrawer(Position, true)}>
				<MenuIcon />
			</IconButton>
			<Drawer
				anchor={Position}
				open={isOpen}
				onClose={toggleDrawer(Position, false)}
			>
				<List onClick={toggleDrawer(Position, false)}>
					<ListItemButton component={Link} to="/" sx={{ mx: 4 }}>
						<Logo />
					</ListItemButton>
					{NAVIGATION_LIST.map((item: TypeNavigation) => (
						<ListItemButton
							divider
							selected={isSelected?.to === item.to ?? false}
							component={Link}
							to={item.to}
						>
							<ListItemText primary={t(item.name)} sx={{ px: 2 }} />
						</ListItemButton>
					))}
				</List>
				<Box mx="auto" mt={2} display="flex">
					<LangSwitcher />
				</Box>
			</Drawer>
		</>
	);
};

export default MobileNavigation;

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/system';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './i18n/config';
import store from './lib/store';
import Routes from './pages/Routes';
import theme from './theme/theme';

const App = () => (
	<Provider store={store}>
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</ThemeProvider>
		</HelmetProvider>
	</Provider>
);

export default App;

enum Pathnames {
	HOME = '/',
	JOB_OFFERS = '/job-offers',
	REFERENCES = '/references',
	FAQ = '/consulting',
	ABOUT_US = '/about-us',
	CONTACT = '/contact',
	ADMIN = '/admin'
}

export default Pathnames;

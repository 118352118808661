import { List, ListItem, ListItemText, styled } from '@mui/material';

import theme from '../../theme/theme';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';

const StyledComponent = styled('div')({
	'& .MuiList-dense': {
		marginLeft: 0,

		'& .MuiTypography-body2': {
			margin: 0
		},

		'& .MuiListItemText-root': {
			color: 'white',
			margin: 0
		}
	},

	'& .MuiSvgIcon-root': {
		color: theme.palette.grey['500']
	},

	'& .MuiTypography-body1': {
		textTransform: 'uppercase',
		letterSpacing: 2
	},
	'& .MuiTypography-body2 a': {
		color: 'white',
		fontWeight: 600,
		textDecoration: 'none'
	}
});

const BillingInfo = () => {
	const t = useTranslate('contact');

	return (
		<StyledComponent>
			<Typography variant="h5">{t('billing_details.title')}</Typography>
			<List disablePadding dense>
				<ListItem disableGutters disablePadding>
					<ListItemText primary="HLSK s. r. o." />
				</ListItem>
				<ListItem disableGutters disablePadding>
					<ListItemText primary="Kuková 40, 086 44 Kuková" />
				</ListItem>
				<ListItem disableGutters disablePadding>
					<ListItemText primary="IČO: 52761151" />
				</ListItem>
				<ListItem disableGutters disablePadding>
					<ListItemText primary="DIČ: 2121130385" />
				</ListItem>
				<ListItem disableGutters disablePadding>
					<ListItemText primary="Nie sme platcami DPH" />
				</ListItem>
			</List>
		</StyledComponent>
	);
};

export default BillingInfo;

import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import theme from '../../theme/theme';

interface Props {
	sidebar?: JSX.Element;
	mainContent: JSX.Element;
}

const StyledComponent = styled(Grid)({
	'& .MuiListItemText-primary': {},
	'& .MuiListItemText-secondary': {
		fontWeight: 600,
		color: theme.palette.text
	},
	'& .MuiTypography-h2': {
		fontSize: 24,
		marginBottom: 16,
		marginTop: 16
	},
	'& .MuiTypography-h3': {
		// marginBottom: 16
	},
	'& .MuiTypography-body1': {
		// fontSize: 20
	},
	'& .MuiTypography-body2': {},
	'& .MuiListItemIcon-root': {
		minWidth: 32
	}
});

const ContentSkeleton = ({ sidebar, mainContent }: Props) => (
	<StyledComponent container spacing={2} width="100%">
		{sidebar && (
			<Grid item md={3}>
				{sidebar}
			</Grid>
		)}
		<Grid item md={sidebar ? 9 : 12}>
			{mainContent}
		</Grid>
	</StyledComponent>
);

export default ContentSkeleton;

import { Paper, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'& .MuiToggleButtonGroup-grouped': {
		margin: theme.spacing(0.5),
		border: 0,
		'&.Mui-disabled': {
			border: 0
		},
		'&:not(:first-of-type)': {
			borderRadius: theme.shape.borderRadius
		},
		'&:first-of-type': {
			borderRadius: theme.shape.borderRadius
		}
	}
}));

const LangSwitcher = () => {
	const { i18n } = useTranslation();

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		value: string
	) => {
		i18n.changeLanguage(value);
	};

	return (
		<Paper
			elevation={0}
			sx={{
				display: 'flex',
				border: (theme) => `1px solid ${theme.palette.divider}`,
				flexWrap: 'wrap'
			}}
		>
			<StyledToggleButtonGroup
				size="small"
				value={i18n.language}
				exclusive
				onChange={handleChange}
				aria-label="text alignment"
			>
				{i18n.options.languages.map((lang: string) => (
					<ToggleButton value={lang} key={lang}>
						{lang}
					</ToggleButton>
				))}
			</StyledToggleButtonGroup>
		</Paper>
	);
};

export default LangSwitcher;

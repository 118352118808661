import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TodayIcon from '@mui/icons-material/Today';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography
} from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from 'react-router-dom';
import Pathnames from '../../routes/pathnames';
import useTranslate from '../translation/useTranslate';
import { JobOfferType } from './useJobOffers';

interface Props {
	item: JobOfferType;
}

const JobOffer = ({ item }: Props) => {
	const navigate = useNavigate();
	const t = useTranslate('buttons');
	const tt = useTranslate('offer_job');

	return (
		<Card variant="outlined">
			<CardContent sx={{ pb: 0 }}>
				<Typography variant="h3">
					{item.title} <ReactCountryFlag countryCode="DE" />
				</Typography>
				<List dense>
					<ListItem divider disableGutters>
						<ListItemIcon>
							<EuroSymbolIcon />
						</ListItemIcon>
						<ListItemText primary={tt('salary')} secondary={item.salary} />
					</ListItem>
					<ListItem divider disableGutters>
						<ListItemIcon>
							<LocationOnIcon />
						</ListItemIcon>
						<ListItemText primary={tt('location')} secondary={item.location} />
					</ListItem>
					<ListItem divider disableGutters>
						<ListItemIcon>
							<TodayIcon />
						</ListItemIcon>
						<ListItemText primary={tt('startAt')} secondary={item.startDate} />
					</ListItem>
				</List>
			</CardContent>
			<CardActions>
				<Button
					variant="contained"
					fullWidth
					size="small"
					endIcon={<ChevronRightIcon />}
					onClick={() =>
						navigate(`${Pathnames.JOB_OFFERS}/${item.id}/${item.pathname}`)
					}
					disableElevation
				>
					{t(`moreInfo`)}
				</Button>
			</CardActions>
		</Card>
	);
};

export default JobOffer;

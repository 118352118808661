import { Container, Grid, styled } from '@mui/material';
import { useEffect } from 'react';
import theme from '../../theme/theme';
import HeaderPage from '../page/HeaderPage';
import useTranslate from '../translation/useTranslate';
import JobOffer from './JobOffer';
import useJobOffers, { JobOfferType } from './useJobOffers';

const StyledComponent = styled(Container)({
	marginBottom: 32,
	'& .MuiListItemText-dense': {
		display: 'flex',
		justifyContent: 'space-between'
	},
	'& .MuiListItemText-primary': {},
	'& .MuiListItemText-secondary': {
		fontWeight: 600,
		color: theme.palette.text
	},
	'& .MuiTypography-h3': {
		marginBottom: 30
	},
	'& .MuiTypography-body1': {
		fontSize: 20
	},
	'& .MuiTypography-body2': {},
	'& .MuiListItemIcon-root': {
		minWidth: 32
	}
});

const JobOffers = () => {
	const t = useTranslate('offer_job');
	const jobOffers = useJobOffers();

	useEffect(() => {
		jobOffers.getList();
	}, []);

	return (
		<StyledComponent>
			<HeaderPage title={t('title')} description={t('description')} />

			<Grid container>
				{/* <Grid item xs={12} lg={2}>
					Filter
				</Grid> */}
				<Grid container spacing={2} item xs={12} lg={12}>
					{jobOffers.list.map((montage: JobOfferType) => (
						<Grid item xs={12} sm={6} lg={4}>
							<JobOffer item={montage} />
						</Grid>
					))}
				</Grid>
			</Grid>
		</StyledComponent>
	);
};

export default JobOffers;

import {
	AppBar as MuiAppBar,
	Container,
	Stack,
	useMediaQuery,
	useTheme
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '../box/Box';
import UserContact from '../contact/UserContact';
import Logo from '../logo/Logo';
import MobileNavigation from '../navigation/MobileNavigation';
import Navigation from '../navigation/Navigation';
import LangSwitcher from '../translation/LangSwitcher';
import Toolbar from './Toolbar';

const AppBar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box sx={{ display: 'flex', height: 100, mb: 2 }}>
			<CssBaseline />
			<MuiAppBar component="nav" position="fixed">
				<Toolbar sx={{ height: 100, background: 'white' }}>
					<Container disableGutters={false} maxWidth="lg">
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Logo />
							{!isMobile && (
								<Box
									sx={{
										direction: 'row',
										display: 'flex',
										alignItems: 'center',
										gap: 5
									}}
								>
									<Navigation />
									<UserContact
										name="Štefan Pastirčak"
										phone="+421 948 125 390"
									/>
									<LangSwitcher />
								</Box>
							)}

							{isMobile && <MobileNavigation />}
						</Stack>
					</Container>
				</Toolbar>
			</MuiAppBar>
		</Box>
	);
};

export default AppBar;

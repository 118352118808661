import { ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import palette from '../palette';

const MuiAppBar: {
	defaultProps: ComponentsProps['MuiAppBar'];
	variants: ComponentsVariants['MuiAppBar'];
} = {
	defaultProps: {
		style: {
			background: 'transparent',
			boxShadow: 'none',
			color: palette?.text?.primary
		}
	},
	variants: [
		{
			props: {},
			style: {
				//
			}
		}
	]
};

export default MuiAppBar;

import { Typography as MuiTypography, TypographyProps } from '@mui/material';

const Typography = ({
	children,
	...rest
}: TypographyProps<React.ElementType, { component?: React.ElementType }>) => (
	<MuiTypography {...rest}>{children}</MuiTypography>
);

export default Typography;

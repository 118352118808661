import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { StateProps } from '../../@types/states';

export interface AppSlice {
	company: string;
}

const initialState: AppSlice = {
	company: 'HLSK s.r.o.'
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {}
});

const useApp = () => {
	const app = useSelector((state: StateProps) => state.app);
	return {
		app
	};
};

export default useApp;

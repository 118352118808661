import Container from '@mui/material/Container';
import FaqHome from '../home/FaqHome';
import ContentSkeleton from '../page/ContentSkeleton';
import HeaderPage from '../page/HeaderPage';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';

const AboutUs = () => {
	const t = useTranslate('about_us');

	return (
		<>
			<Container sx={{ mb: 4 }}>
				<HeaderPage title={t('title')} description={t('description')} />
				<ContentSkeleton
					mainContent={
						<>
							<Typography variant="h2">
								{t('our_specialization.title')}
							</Typography>
							<Typography>{t('our_specialization.text')}</Typography>
							<Typography variant="h2">{t('cooperation.title')}</Typography>
							<Typography>{t('cooperation.text')}</Typography>
							<Typography variant="h2">{t('history.title')}</Typography>
							<Typography>{t('history.text')}</Typography>
						</>
					}
				/>
			</Container>
			<FaqHome />
		</>
	);
};

export default AboutUs;

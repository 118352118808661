import Box from '../box/Box';
import Seo from '../seo/Seo';
import Typography from '../typography/Typography';

interface Props {
	title: string;
	description?: string;
}

const HeaderPage = ({ title, description = '' }: Props) => (
	<>
		<Seo title={title} description={description} />
		<Box mb={8}>
			<Typography variant="h1" textAlign="center" marginBottom={1}>
				{title}
			</Typography>
			{description && (
				<Typography textTransform="uppercase" fontSize={20} textAlign="center">
					{description}
				</Typography>
			)}
		</Box>
	</>
);

export default HeaderPage;

import Container from '@mui/material/Container';
import Box from '../box/Box';
import Divider from '../divider/Divider';
import FaqHome from '../home/FaqHome';
import ContentSkeleton from '../page/ContentSkeleton';
import HeaderPage from '../page/HeaderPage';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';

const Contact = () => {
	const t = useTranslate('contact');

	return (
		<>
			<Container sx={{ mb: 4 }}>
				<HeaderPage title={t('title')} description={t('description')} />
				<ContentSkeleton
					sidebar={
						<>
							<Typography variant="h3">{t('address.title')}</Typography>
							<Typography>HLSK s. r. o., Kuková 40, 086 44 Kuková</Typography>
							<Box mt={3} />
							<Typography variant="h3">{t('contact.title')}</Typography>
							<Typography>0948 125 390</Typography>
							<Box mt={3} />
							<Typography variant="h3">{t('email.title')}</Typography>
							<Typography>info@hlsk.eu</Typography>
							<Box mt={3} />
							<Typography variant="h3">{t('finance.title')}</Typography>
							<Typography>faktury@hlsk.eu</Typography>
							<Box mt={3} />
							<Typography variant="h3">{t('other.title')}</Typography>
							<Typography>IČO: 52761151</Typography>
							<Typography>DIČ:2121130385</Typography>
							<Divider sx={{ my: 2 }} />
							<Typography mb={2}>
								Spoločnosť zapísaná v Obchodnom registri Okresného súdu Prešov,
								Oddiel: Sro, Vložka číslo: 39335/P
							</Typography>
							<Typography>Nie sme platcami DPH.</Typography>
						</>
					}
					mainContent={<Typography variant="h3">Napíšte nám</Typography>}
				/>
			</Container>
			<FaqHome />
		</>
	);
};

export default Contact;

import { enUS } from '@mui/material/locale';
import {
	Components,
	createTheme,
	Palette,
	PaletteOptions,
	responsiveFontSizes,
	Theme
} from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import MuiAppBar from './components/MuiAppBar';
import MuiButton from './components/MuiButton';

import palette from './palette';
import typography from './typography';

interface ThemeOptionsProps {
	components?: Components<Omit<Theme, 'components'>>;
	typography?: TypographyOptions | ((palette: Palette) => TypographyOptions);
	palette: PaletteOptions;
}

export const THEMES = {
	name: 'WEB_APP',
	grey: '#c5c5c5'
};

const ThemeOptions: ThemeOptionsProps = {
	typography,
	palette,
	components: {
		MuiAppBar,
		MuiButton
	}
};

const theme = createTheme(ThemeOptions, { direction: 'ltr' }, enUS);

export const themeWithLanguage = responsiveFontSizes(theme);
export default responsiveFontSizes(theme);

import { List, ListItemButton, ListItemText, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { TypeNavigation } from '../navigation/Navigation';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';

const StyledComponent = styled(List)({
	'& .MuiListItemButton-root': {
		color: 'white',

		'&.Mui-selected': {
			// textDecoration: 'underline'
		},
		'& span': {
			marginBottom: 0
		}
	},

	'& .MuiListItemButton-root:hover': {
		backgroundColor: 'transparent',

		'& span': {
			marginBottom: 0,
			textDecoration: 'underline'
		}
	},
	'& .MuiTypography-body1': {
		marginBottom: 0
	}
});

interface Props {
	title?: string;
	list: TypeNavigation[];
	scope: string;
}

const FooterNavigation = ({ title, list, scope }: Props) => {
	const t = useTranslate(scope);
	const location = useLocation();
	const navigate = useNavigate();
	const isSelected = list.find((nav) => nav.to === location.pathname);

	return (
		<StyledComponent disablePadding>
			{title && <Typography variant="h5">{title}</Typography>}
			{list.map((item) => (
				<ListItemButton
					key={item.name}
					onClick={() => navigate(item.to)}
					selected={isSelected?.to === item.to ?? false}
					disableGutters
					disableRipple
					disableTouchRipple
					dense
				>
					<ListItemText primary={t(item.name)} sx={{ margin: 0 }} />
				</ListItemButton>
			))}
		</StyledComponent>
	);
};

export default FooterNavigation;

import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TodayIcon from '@mui/icons-material/Today';
import { Grid } from '@mui/material';
import Container from '@mui/system/Container';
import Stack from '@mui/system/Stack';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import AccordionList from '../accordionList/AccordionList';
import Benefits from '../benefits/Benefits';
import Box from '../box/Box';
import { FAQ_LIST } from '../faq/Faq';
import FaqHome from '../home/FaqHome';
import Seo from '../seo/Seo';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';
import useJobOffers, { JobOfferType } from './useJobOffers';

const Job = () => {
	const params = useParams();
	const job = useJobOffers();
	const tt = useTranslate('offer_job');
	const { offer } = job;

	useEffect(() => {
		const id = params.id as JobOfferType['id'];
		job.getOffer(id);

		return () => job.reset();
	}, []);

	if (!offer) {
		return <Container>No job available!</Container>;
	}

	return (
		<>
			<Seo title={offer.title} description={offer.description} />
			<Container sx={{ mb: 4 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={9}>
						<Stack spacing={4}>
							<Typography variant="h1">{offer.title}</Typography>
							<Typography>{offer.description}</Typography>
							<Box mt={2}>
								<Grid container spacing={2}>
									<Grid item xs={12} md={4}>
										<Box
											display="flex"
											flexDirection="column"
											sx={{
												p: 2,
												gap: 1,
												borderRadius: 1,
												background: theme.palette.grey['100']
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													pb: 1,
													gap: 1,
													borderBottom: `1px solid ${theme.palette.primary.main}`
												}}
											>
												<LocationOnIcon height={16} />
												<Typography>{tt('location')}</Typography>
											</Box>
											<Typography variant="h4">{offer.location}</Typography>
										</Box>
									</Grid>
									<Grid item xs={12} md={4}>
										<Box
											display="flex"
											flexDirection="column"
											sx={{
												p: 2,
												gap: 1,
												borderRadius: 1,
												background: theme.palette.grey['100']
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													pb: 1,
													gap: 1,
													borderBottom: `1px solid ${theme.palette.primary.main}`
												}}
											>
												<TodayIcon height={16} />
												<Typography>{tt('startAt')}</Typography>
											</Box>
											<Typography variant="h4">{offer.startDate}</Typography>
										</Box>
									</Grid>
									<Grid item xs={12} md={4}>
										<Box
											display="flex"
											flexDirection="column"
											sx={{
												p: 2,
												gap: 1,
												borderRadius: 1,
												background: theme.palette.grey['100']
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													pb: 1,
													gap: 1,
													borderBottom: `1px solid ${theme.palette.primary.main}`
												}}
											>
												<EuroSymbolIcon height={16} />
												<Typography>{tt('salary')}</Typography>
											</Box>
											<Typography variant="h4">{offer.salary}</Typography>
										</Box>
									</Grid>
								</Grid>
							</Box>

							<Benefits />

							<Box>
								<Typography variant="h3">Najčastejsie otázky</Typography>
								<AccordionList list={FAQ_LIST} />
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} md={3} />
				</Grid>
			</Container>
			<FaqHome />
		</>
	);
};

export default Job;

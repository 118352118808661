import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	styled
} from '@mui/material';
import theme from '../../theme/theme';
import Box from '../box/Box';
import Typography from '../typography/Typography';

const StyledComponent = styled('div')({
	'& .first': {
		display: 'flex',
		gap: 8,
		marginBottom: 20,

		'& .MuiSvgIcon-root': {
			height: 42,
			width: 42,
			color: theme.palette.grey['500']
		}
	},

	'& .MuiList-dense': {
		marginLeft: 10,

		'& .MuiTypography-body2': {
			margin: 0
		},

		'& .MuiListItemText-root': {
			color: 'white'
		}
	},

	'& .MuiSvgIcon-root': {
		color: theme.palette.grey['500']
	},

	'& .MuiTypography-body1': {
		textTransform: 'uppercase',
		letterSpacing: 2
	},
	'& .MuiTypography-body2 a': {
		color: 'white',
		fontWeight: 600,
		textDecoration: 'none'
	}
});

interface Props {
	name: string;
	phone: string;
	position: string;
	email: string;
}

const FooterContact = ({ name, phone, position, email }: Props) => (
	<StyledComponent>
		<Box className="first">
			<AccountCircleIcon />
			<Box>
				<Typography variant="body1" fontWeight={600} fontSize={16} mb={0}>
					{name}
				</Typography>
				<Typography variant="body1" fontSize={12} mb={0}>
					{position}
				</Typography>
			</Box>
		</Box>

		<List disablePadding dense>
			<ListItem disableGutters disablePadding>
				<ListItemIcon>
					<SmartphoneIcon />
				</ListItemIcon>
				<ListItemText primary={phone} />
			</ListItem>
			<ListItem disableGutters disablePadding>
				<ListItemIcon>
					<AlternateEmailIcon />
				</ListItemIcon>
				<ListItemText primary={email} />
			</ListItem>
		</List>
	</StyledComponent>
);

export default FooterContact;

import '@fontsource/open-sans';
import MuiPalette from './palette';

const typography = {
	h1: {
		fontFamily: 'Open Sans',
		color: MuiPalette?.text?.primary,
		fontSize: '2.5rem',
		lineHeight: 1.4,
		fontWeight: 600
	},
	h2: {
		fontFamily: 'Open Sans',
		color: MuiPalette?.text?.primary,
		fontSize: '2.0rem',
		lineHeight: 1.3,
		fontWeight: 700,
		marginBottom: 20
	},
	h3: {
		fontFamily: 'Open Sans',
		color: MuiPalette?.text?.primary,
		fontSize: 20,
		lineHeight: 1.3,
		fontWeight: 600
	},
	h4: {
		fontFamily: 'Open Sans',
		color: MuiPalette?.text?.primary,
		fontSize: 18,
		lineHeight: 1.3,
		fontWeight: 600
	},
	h5: {
		fontFamily: 'Open Sans',
		color: MuiPalette?.text?.primary,
		fontSize: 18,
		lineHeight: 1.3,
		fontWeight: 600,
		marginBottom: 8
	},
	body1: {
		fontSize: 15,
		fontFamily: 'Open Sans'
		// marginBottom: 8
	},
	body2: {
		fontSize: 15,
		fontFamily: 'Open Sans'
		// marginBottom: 8
	}
};

export default typography;

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material';
import theme from '../../theme/theme';
import Box from '../box/Box';
import Typography from '../typography/Typography';

const StyledComponent = styled('div')({
	display: 'flex',
	gap: 8,

	'& .MuiSvgIcon-root': {
		height: 42,
		width: 42,
		color: theme.palette.grey['500']
	},

	'& .MuiTypography-body1': {
		fontSize: 12,
		textTransform: 'uppercase',
		color: theme.palette.primary.main,
		letterSpacing: 2
	},
	'& .MuiTypography-body2 a': {
		color: theme.palette.info.main,
		fontWeight: 600,
		textDecoration: 'none'
	}
});

interface Props {
	name: string;
	phone: string;
}

const UserContact = ({ name, phone }: Props) => (
	<StyledComponent>
		<AccountCircleIcon />
		<Box>
			<Typography variant="body1" mb={0}>
				{name}
			</Typography>
			<Typography variant="body2">
				<a href={`tel:${phone}`}>{phone}</a>
			</Typography>
		</Box>
	</StyledComponent>
);

export default UserContact;

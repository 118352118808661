import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './de/translation.json';
import en from './en/translation.json';
import sk from './sk/translation.json';

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV !== 'production',
		resources: {
			sk,
			de,
			en
		},
		languages: ['sk', 'en', 'de'],
		ns: ['translations'],
		defaultNS: 'translations',
		react: {
			useSuspense: false //   <---- this will do the magic
		}

		// if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
		// set returnNull to false (and also in the i18next.d.ts options)
		// returnNull: false,
	});

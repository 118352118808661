import { Outlet } from 'react-router-dom';
import AppBar from '../components/appBar/AppBar';
import Footer from '../components/footer/Footer';

const Layout = () => (
	<>
		<AppBar />
		<Outlet />
		<Footer />
	</>
);

export default Layout;

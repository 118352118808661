import {
	Container,
	Grid,
	styled,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import Box from '../box/Box';
import Divider from '../divider/Divider';
import { NAVIGATION_LIST } from '../navigation/Navigation';
import useTranslate from '../translation/useTranslate';
import Typography from '../typography/Typography';
import BillingInfo from './BillingInfo';
import FooterContact from './FooterContact';
import FooterFaq from './FooterFaq';
import FooterNavigation from './FooterNavigation';

const StyledComponent = styled(Box)({
	'& .MuiTypography-h5': {
		color: 'white'
		// marginBottom: 30
	},

	'& .MuiListItemText-primary': {},
	'& .MuiListItemText-secondary': {
		// fontWeight: 600,
		color: 'white'
	},

	'& .MuiTypography-body1': {
		// fontSize: 20
		color: 'white'
	},
	'& .MuiTypography-body2': {},
	'& .MuiListItemIcon-root': {
		minWidth: 32
	}
});

const Footer = () => {
	const t = useTranslate('contact');
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<StyledComponent
			mt={0}
			pt={8}
			sx={{ background: theme.palette.primary.main }}
		>
			<Container>
				<Grid
					container
					spacing={isMobile ? 5 : 3}
					justifyContent="space-between"
				>
					{!isMobile && (
						<Grid item xs={12} md={2}>
							<FooterNavigation
								title="HLSK"
								list={NAVIGATION_LIST}
								scope="navigation"
							/>
						</Grid>
					)}
					{!isMobile && (
						<Grid item xs={12} md={3}>
							<FooterFaq />
						</Grid>
					)}
					<Grid item xs={12} md={3}>
						<BillingInfo />
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography variant="h5">{t('title')}</Typography>
						<FooterContact
							name="Štefan Pastirčak"
							phone="+421 948 125 390"
							position="Podpora"
							email="info@hlsk.eu"
						/>
					</Grid>
				</Grid>
			</Container>
			<Box pt={4} />
			<Divider sx={{ background: 'white', opacity: 0.4 }} />
			<Container sx={{ py: 2 }}>
				<Grid container spacing={2} justifyContent="space-between">
					<Grid item>
						<Typography>
							&copy; {new Date().getFullYear()} HLSK s.r.o.
						</Typography>
					</Grid>
					<Grid item>
						<Link
							to="https://www.weblab.ltd"
							target="_blank"
							style={{ color: 'white', textDecoration: 'none' }}
						>
							Made by <strong>Weblab</strong>
						</Link>
					</Grid>
				</Grid>
			</Container>
		</StyledComponent>
	);
};

export default Footer;

import '@fontsource/open-sans';
import { Link } from 'react-router-dom';
import logo from './logo.webp';

const Logo = () => (
	<Link to="/" style={{ marginTop: 20 }}>
		<img src={logo} alt="Logo" height={100} />
	</Link>
);

export default Logo;
